.head {
  line-height: 32px;
}
.attach {
  width: 260px;
  float: right;
  color: #FFF;
}
.attach > div {
  width: 50%;
  padding: 0 8px;
  display: inline-block;
}
.summary {
  color: #FFF;
  margin: 12px 0;
}
.summary > div {
  width: 50%;
  display: inline-block;
  text-align: center;
  vertical-align: top;
}
.summary > div:first-child {
  font-size: 20px;
  line-height: 55px;
}
.summary > div:last-child {
  font-size: 18px;
}
.chartTitle {
  font-size: 14px;
  margin-left: 6px;
  border-bottom: 1px solid #FFF;
  color: white;
}
.departments {
  width: 50%;
  display: inline-block;
  padding: 4px;
}
.departments ul {
  color: white;
  font-size: 16px;
  padding: 6px;
}
.departments label {
  display: inline-block;
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
}
.departments div {
  margin-left: 50%;
}
.departments::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  cursor: pointer;
}
.departmentBox {
  margin-top: 12px;
  flex-grow: 1;
  overflow: auto;
  padding: 0 12px;
}
.childrenCharts {
  width: 50%;
  display: inline-block;
  height: 100%;
  padding: 0 4px;
  float: left;
}
